import axios from "axios";
import Cookies from "js-cookie";
import { Message } from "element-ui";
import { TOKEN_KEY } from "@/common/constants";

const api = axios.create({
  baseURL: process.env.VUE_APP_CORE_API,
  timeout: 100000, // 超时时间
  headers: {
    "Content-Type": "application/json",
  },
  widthCredentials: true,
  validateStatus: (status) => status === 200,
});

// 请求拦截
api.interceptors.request.use(
  (apiconfig) => {
    const userToken = Cookies.get(TOKEN_KEY);

    apiconfig.headers["Token"] = userToken;
    return apiconfig;
  },
  (e) => {
    return Promise.reject(e);
  }
);

// 响应拦截
api.interceptors.response.use(
  (res = {}) => {
    try {
      const { data } = res;
      if (+data.code === 200) {
        return Promise.resolve(data.data);
      } else if (+data.code === 401) {
        // 登陆过期
        Cookies.remove(TOKEN_KEY);
        Message.error("token过期，请重新登陆");
        if (location.pathname.indexOf("login") < 0) {
          location.href = "/login";
        } else {
          location.reload()
        }
        return;
      } else {
        Message.error(data.message);
        return Promise.reject(res);
      }
    } catch (e) {
      // HTTP状态码 !== 200
      return Promise.reject(e);
    }
  },
  (e) => {
    return Promise.reject(e);
  }
);

export default api;
