<template>
  <div class="table-item">
    <div class="table-title">
      <div class="title">
        {{ tableItem.category.Name }}
      </div>
      <el-button
        type="text"
        v-if="categorySelect === 'ALL'"
        @click="$emit('onMore', tableItem.category.APIName)"
        >查看更多</el-button
      >
    </div>

    <el-table :data="tableItem.list" class="table-customer">
      <el-table-column
        v-for="fieldObj in column"
        :prop="fieldObj.APIName"
        :label="fieldObj.Name"
        :key="fieldObj.APIName"
      >
        <template slot-scope="scope">
          <router-link
            v-if="fieldObj['IsNameField'] == '1'"
            :to="scope.row['__RecordURL']"
            class="g-link"
            target="_blank"
          >
            {{ scope.row[fieldObj.APIName] }}
          </router-link>
          <router-link
            v-else-if="fieldObj['IsNameField'] == '2'"
            :to="scope.row[fieldObj.APIName]['__RecordURL']"
            class="g-link"
            target="_blank"
          >
            {{ scope.row[fieldObj.APIName]["APIName"] }}
          </router-link>
          <span v-else>{{ scope.row[fieldObj.APIName] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :total="pagination.totalCount"
      :current-page="pagination.currentPage"
      :page-size="pagination.pageSize"
      @current-change="handlePage"
      v-if="categorySelect !== 'ALL'"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableItem: Object,
    categorySelect: String,
    column: [],
    pagination: {
      type: Object,
      default: () => {
        return {
          pageSize: 12,
          totalCount: {
            default: 0,
          },
          currentPage: 1,
        };
      },
    },
  },
  methods: {
    handlePage(index) {
      this.$emit("changeCurrentPage", index);
    },
  },
};
</script>

<style lang="less" scoped>
.table-item {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
    0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin-bottom: 30px;

  .table-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 66px;
    margin-bottom: 15px;
    .title {
      color: #000000;
      font-size: 24px;
      line-height: 32px;
    }
  }
}
</style>
