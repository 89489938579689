<template>
  <div>
    <div class="query-key">搜索 {{ queryVal }}</div>
    <el-tabs v-model="categorySelect" style="margin-bottom: 10px">
      <el-tab-pane label="所有" name="ALL"> </el-tab-pane>
      <el-tab-pane
        :label="item.Name"
        :name="item.APIName"
        v-for="item in categoryList"
        :key="item.id"
      >
      </el-tab-pane>
    </el-tabs>
    <div
      v-if="!queryVal"
      class="content-wrapper"
      style="
        height: 100px;
        text-align: center;
        font-size: 20px;
        line-height: 50px;
        margin-top: -10px;
      "
    >
      请在页面头部搜索框里输入关键词搜索
    </div>
    <div v-loading="loading" style="margin-top: 10px">
      <table-item
        v-for="table in tableList"
        :key="table.id"
        :tableItem="table"
        :column="table.column"
        :categorySelect="categorySelect"
        :pagination="pagination"
        @onMore="onMore"
        @changeCurrentPage="changeCurrentPage"
      ></table-item>
    </div>
  </div>
</template>

<script>
import TableItem from "./components/table-item.vue";
import { mapGetters } from "vuex";
import { apiSearchVal, apiGetCategory } from "@/api/query";
export default {
  components: { TableItem },
  data() {
    return {
      categoryList: [],
      categorySelect: "ALL",
      tableList: [],
      pagination: {
        pageSize: 12,
        totalCount: 0,
        currentPage: 1,
      },
      loading: false,
      ObjectAPINameRecordUrlMap: {
        "/ListView/list": "/group/list",
        "/MessageChannel/list?Type=短信": "/message/list",
        "/MessageChannel/list?Type=微信公众号": "/gzhao/list",
        "/MessageChannel/list?Type=企业微信": "/companywx/list",
        "/MessageChannel/list?Type=微信小程序": "/miniprogram/list",
        "/MessageChannel/list?Type=APP": "/app/list",
        "/MessageTemplate": "/template/list",
        "/MessageTemplate/list": "/template/list",
        "/User": "/user/index",
        "/ListView": "/group/detail",
        "/Campaign": "/plan/detail",
        "/Customer": "/customer/info",
        "/Car": "/car/info",
      },
    };
  },
  computed: {
    ...mapGetters({
      queryVal: "common/queryVal",
      userInfo: "common/userInfo",
    }),
    // 选择的分类对象
    getCategorySelectObj() {
      let res = this.categoryList.find((item) => {
        return item.APIName === this.categorySelect;
      });
      return res;
    },
  },

  watch: {
    categorySelect() {
      this.pagination.currentPage = 1;
      this.pagination.totalCount = 0;
      this.getList();
    },
    categoryList() {
      this.getList();
    },
    queryVal() {
      this.categorySelect = "ALL";
      this.getList();
    },
  },
  methods: {
    onMore(APIName) {
      this.pagination.currentPage = 1;
      this.pagination.totalCount = 0;
      this.categorySelect = APIName;
    },
    // 切换分页
    changeCurrentPage(currentPage) {
      this.pagination.currentPage = currentPage;
      this.getOneList();
    },
    changeRecordUrl(url) {
      if (!url) {
        return "";
      }
      let newUrl = url;
      const regOne = function (name, url) {
        const reg = new RegExp("(\\?|&)" + name + "=(.*?)(&|$)");
        let value = "";
        try {
          value = url.match(reg)[2];
        } catch (error) {
          console.log(error);
        }
        return value;
      };

      const regTwo = function (name, url) {
        const reg = new RegExp(name + "/");
        let value = "";
        try {
          value = url.replace(reg, "");
        } catch (error) {
          console.log(error);
        }
        return value;
      };

      if (url.indexOf("/MessageChannel/list") > -1) {
        newUrl = `${
          this.ObjectAPINameRecordUrlMap[
            `/MessageChannel/list?Type=${regOne("Type", url)}`
          ]
        }?id=${regOne("RecordId", url)}&action=edit`;
      } else {
        if (url.indexOf("/ListView/list") > -1) {
          newUrl = `${
            this.ObjectAPINameRecordUrlMap["/ListView/list"]
          }?id=${regTwo("/ListView/list", url)}&action=edit`;
        } else if (url.indexOf("/MessageTemplate") > -1) {
          if (url.indexOf("/MessageTemplate/list") > -1) {
            newUrl = `${
              this.ObjectAPINameRecordUrlMap["/MessageTemplate/list"]
            }?id=${regOne("RecordId", url)}&status=edit`;
          } else {
            newUrl = `${
              this.ObjectAPINameRecordUrlMap["/MessageTemplate"]
            }?id=${regTwo("/MessageTemplate", url)}&status=edit`;
          }
        } else if (url.indexOf("/User") > -1) {
          newUrl = `${this.ObjectAPINameRecordUrlMap["/User"]}?id=${regTwo(
            "/User",
            url
          )}&action=edit`;
        } else if (url.indexOf("/ListView") > -1) {
          newUrl = `${this.ObjectAPINameRecordUrlMap["/ListView"]}/${regTwo(
            "/ListView",
            url
          )}`;
        } else if (url.indexOf("/Campaign") > -1) {
          newUrl = `${this.ObjectAPINameRecordUrlMap["/Campaign"]}/${regTwo(
            "/Campaign",
            url
          )}`;
        } else if (url.indexOf("/Customer") > -1) {
          newUrl = `${this.ObjectAPINameRecordUrlMap["/Customer"]}/${regTwo(
            "/Customer",
            url
          )}`;
        } else if (url.indexOf("/Car") > -1) {
          newUrl = `${this.ObjectAPINameRecordUrlMap["/Car"]}/${regTwo(
            "/Car",
            url
          )}`;
        }
      }

      return newUrl;
    },
    changeTableData(list, column) {
      (list || []).forEach((item) => {
        const keys = Object.keys(item);
        keys.forEach((key) => {
          const findField = column.find((fieldObj) => {
            return fieldObj.APIName == key;
          });
          if (findField) {
            // 手机号脱敏
            // if (findField.DataType == "MobilePhone") {
            //   if(item["Phone"]) {
            //     item["Phone"] = item["Phone"].replace(/(\d{3})\d*(\d{4})/,'$1****$2');
            //   }
            // }
            // 此时，这个字段是超链接，加个标记
            if (findField.IsNameField == "1") {
              if (item["__RecordURL"]) {
                item["__RecordURL"] = this.changeRecordUrl(item.__RecordURL);
              }
            } else {
              // 此时这个字段值类似：{_id:614b4ed53c6a9f7f023c6d5a, NameFieldValue:"testyin测试", __RecordURL:"/ListView/614b4ed53c6a9f7f023c6d5a"}，然后改造这个值
              if (["Lookup", "MasterDetail"].indexOf(findField.DataType) > -1) {
                findField["IsNameField"] = "2";
                item[key]["APIName"] = item[key].NameFieldValue;
                if (item[key].__RecordURL) {
                  item[key]["__RecordURL"] = this.changeRecordUrl(
                    item[key].__RecordURL
                  );
                }
              }
            }
          }
        });
      });
      return list || [];
    },
    // 获取单个分类    Lookup、MasterDetail
    /**
     * CustomerGroup : {_id:614b4ed53c6a9f7f023c6d5a, NameFieldValue:"testyin测试", __RecordURL:"/ListView/614b4ed53c6a9f7f023c6d5a"},
     */
    getOneList() {
      this.tableList = [];
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        value: this.queryVal,
      };
      apiSearchVal(this.categorySelect, param)
        .then((res) => {
          this.tableList = [];
          this.tableList.push({
            category: this.getCategorySelectObj,
            list: this.changeTableData(res.result, res.column),
            column: res.column,
          });
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //   获取全部
    getAllList() {
      this.tableList = [];
      this.loading = true;
      const fetching = [];
      this.categoryList.forEach((item) => {
        fetching.push(
          apiSearchVal(item.APIName, {
            value: this.queryVal,
            offset: 0,
            limit: 5,
          })
        );
      });

      Promise.all(fetching)
        .then((res) => {
          if (this.categorySelect === "ALL") {
            this.tableList = [];
            res.forEach((item, index) => {
              this.tableList.push({
                category: this.categoryList[index],
                list: this.changeTableData(item.result, item.column),
                column: item.column,
              });
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getList() {
      if (!this.queryVal) {
        return;
      }
      if (this.categorySelect === "ALL") {
        this.getAllList();
      } else {
        this.getOneList();
      }
    },
    getModel(APIName) {
      const ObjectList = this.userInfo.Permissions.ObjectList || [];
      const findObj = ObjectList.find((item) => {
        return item.APIName === APIName;
      });
      const permission = {
        show: false,
        create: false,
        originData: findObj,
      };
      if (findObj) {
        const BasePermissions = findObj.BasePermissions || [];
        BasePermissions.forEach((item) => {
          if (item.APIName === "PermissionsRead" && item.Value === 1) {
            permission.show = true;
          }
          if (item.APIName === "PermissionsCreate" && item.Value === 1) {
            permission.create = true;
          }
        });
      }

      return permission;
    },
    getCateGoryList() {
      apiGetCategory({
        AllowSearch: "1",
      })
        .then((res) => {
          this.categoryList = [];
          res.forEach((item) => {
            const permission = this.getModel(item.APIName);
            if (permission.show) {
              this.categoryList.push(item);
            }
          });
        })
        .finally(() => {});
    },
  },
  created() {
    this.getCateGoryList();
  },
};
</script>

<style lang="less" scoped>
.query-key {
  color: #26262c;
  font-size: 34px;
  line-height: 36px;
  margin: 18px 0 45px;
}
</style>
